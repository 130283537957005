<template>
    <div class="recharge-wrap">
      <div class="deposit-header-box">
        <van-nav-bar
            :title="isRecharge ? 'USDT充值' : 'USDT提现'"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="direction"></div>
      </div>

        <div class="recharge-box">
            <van-form>
                <div class="form-item">
                    <span class="form-label">USDT类型</span>
                    <!--   类型增加的使用注释里的代码：@click="showPicker = true; bitType = 'type'"  :right-icon="isRecharge ? themeImgs.mySolidDownIcon : ''"  -->
                    <van-field
                            v-model="formData.typeText"
                            readonly
                            name="picker"
                            placeholder="请选择USDT类型"
                    />
                </div>
                <!--    链路暂时只有一个，不做选择  @click="handleClick('link')"  :right-icon="themeImgs.mySolidDownIcon"    -->
                <div class="form-item">
                    <span class="form-label">USDT链路</span>
                    <van-field
                            v-model="formData.linkText"
                            readonly
                            name="picker"
                            placeholder="请选择USDT链路"
                    />
                </div>
                <div class="form-item">
                    <span class="form-label">
                        当前余额({{ store.state.publicConfig.title }})：
                        {{ store.state.memberAmountObj.currency_money }}
                    </span>
                    <span class="form-label form-label2">
                        当前汇率：{{ currentRate }}
                    </span>
                    <van-field
                            class="left-bit-icon"
                            type="number"
                            :left-icon="themeImgs.commonBitIcon"
                            v-model="formData.amount"
                            :placeholder="`请输入要${isRecharge ? '充值' : '提现'}的USDT数量`"
                            :readonly="isReadOnly"
                            @input="inputChangeUnit"
                    />
                </div>
                <div class="form-item">
                    <div class="form-input form-input2" :class="{'form-input2-black': globalSiteTheme === 'black'}">
                        <span class="form-input2-left">报价</span>
                        <span class="form-input2-right">
                            {{ currentVirtual }}{{ formData.typeText }} ≈ {{ currentSysAmount }}{{ store.state.publicConfig.title }}
                        </span>
                    </div>
                </div>
                <div class="form-item">
                    <span class="form-label">
                        {{ isRecharge ? '充币地址' : '提现地址' }}
                        <van-image
                                v-show="isRecharge"
                                class="copy-address-icon"
                                :src="themeImgs.commonCopyIcon"
                                @click="copyAddress(formData.address)"
                        />
                    </span>
                    <van-field
                            v-model="formData.address"
                            :readonly="isRecharge"
                            name="picker"
                            :placeholder="isRecharge ? '请选择USDT地址' : '请输入提现的USDT-TRC20地址'"
                            :right-icon="isRecharge ? themeImgs.mySolidDownIcon : ''"
                            @click="handleClick('wallet')"
                    />
                </div>
                <div class="form-item" v-if="!isRecharge">
                    <span class="form-label">
                        支付密码
                    </span>
                    <van-field
                      v-model="formData.pay_password"
                      name="支付密码"
                      type="password"
                      placeholder="请输入支付密码"
                    />
                </div>
                <div class="form-item">
                    <div class="warning-message">
                        注意：
                        {{ isRecharge ? '转账时请核对收款地址是否一致，避免造成无法追回损失!' : '填写地址时请再次核实是否正确，避免造成无法追回损失！' }}
                    </div>
                  <div class="warning-message">
                        注意：最低{{ isRecharge ? '充值' : '提现' }}{{ minAmount }}USDT起，低于{{ minAmount }}USDT无法提交订单！
                    </div>
                </div>
                <div class="form-item" v-if="isRecharge && formData.pay_pic">
                    <span class="form-label">支付凭证</span>
                    <div class="pay-certificate">
                        <img :src="formData.imgUrl" @click="showFullImage" />
                        <div class="clear-icon" @click="formData.pay_pic = ''; formData.imgUrl = '';">
                            <van-icon
                                    name="close"
                                    size="24px"
                                    :color="'var(--van-field-input-text-color)'"
                            />
                        </div>
                    </div>
                </div>
                <div class="submit-btn">
                    <template v-if="isRecharge">
                        <van-uploader
                                v-if="!formData.pay_pic"
                                class="upload-pay"
                                :max-count="1"
                                :before-read="beforeUpload"
                                :after-read="afterUpload"
                                @click-upload="clickUpload"
                                :preview-image="false"
                        >
                            <van-button block>
                                上传支付凭证
                            </van-button>
                        </van-uploader>
                        <van-button v-else @click="onSubmit" block :disabled="locked">
                            提交充值
                        </van-button>
                    </template>
                    <template v-else>
                        <van-button @click="onSubmit" block :disabled="locked">
                            提交提现
                        </van-button>
                    </template>
                </div>
            </van-form>
        </div>

        <!--    比特币链路选择 start   -->
        <van-popup
                v-model:show="showPicker"
                round
                position="bottom">
            <van-empty v-if="columns.length === 0" description="暂无数据" />
            <van-picker
                    v-else
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false; bitType = ''"
                    :columns-field-names="{ text: 'address' }"
                    confirm-button-text="完成"
            />
        </van-popup>
        <!--    比特币链路选择 end   -->

        <!--    比特币充值上传遮罩   start   -->
        <van-overlay class-name="addPayment-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
        <!--    比特币充值上传遮罩   start   -->
    </div>
</template>

<script setup>
  import { ref, reactive, computed, onMounted, watch, onBeforeUnmount } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from "vue-router";
  import { Toast, ImagePreview } from 'vant'
  import useClipboard from 'vue-clipboard3'
  import { getBitRate, getBitInfo, postBitRecharge, postBitWithdraw, postWithdrawOrderAddV1 } from '@/api/api'
  import { uploadImage } from '@/api/api'

  const store = useStore()

  // mapstate--皮肤对应的图片
  const themeImgs = computed(() => store.state.themeImgs)
    //  当前主题色
  const globalSiteTheme = computed(() => store.state.globalSiteTheme)
  const route = useRoute()
  const router = useRouter()

  //  比特币充值/提现页面判断
  const isRecharge = computed(() => {
    return route.query.type === 'recharge'
  })

  //    充值/提现表单数据对象
  const formData = reactive({
    link: '3',
    linkText: 'trc-20',
    type: '1',
    typeText: 'USDT',
    amount: '',
    address: '',
    pay_pic: '',
    imgUrl: '',
    pay_password: ''
  })


  //    限制比特币输入金额小数位数
  const inputChangeUnit = (e) => {
    formData.amount = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
  }
  //    比特币充币地址
  const handleClick = (type) => {
    bitType.value = type

    //  提现输入充币地址，返回false
    if (!isRecharge.value) {
      return false
    }
    showPicker.value = true
  }

  //    获取汇率配置
  const currentRate = ref('0.00')
  const status = ref(0)
  const minAmount = ref('0.00')
  const getBitRateInfo = () => {
    getBitRate().then(res => {
      if (res.result === 200000) {
        status.value = res.data.info.status
        minAmount.value = res.data.info.min_deposit_withdrawal
        if (isRecharge.value) {
          currentRate.value = res.data.info.status === 2 ? res.data.info.rate : res.data.info.topup_rate
        } else {
          currentRate.value = res.data.info.status === 2 ? res.data.info.rate : res.data.info.withdraw_rate
        }
      } else {
        currentRate.value = '0.00'
      }
    }).catch(_err => {
      currentRate.value = '0.00'
    })
  }
  watch(() => store.state.realTimeRate, (val) => {
    if (val) {
      currentRate.value = status.value === 2 ? store.state.realTimeRate : currentRate.value
    }
  })

  //    计算报价当前虚拟货币
  const currentVirtual = computed(() => {
    if (!formData.amount) {
      return 1
    }
    return formData.amount
  })

  //    计算报价当前系统货币
  const currentSysAmount = computed(() => {
    if (!formData.amount) {
      return 1 * (+currentRate.value)
    }
    return (formData.amount * (+currentRate.value)).toFixed(2)
  })

  //    获取比特币钱包地址
  const walletList = ref([])
  const getBitConfigInfo = () => {
    getBitInfo().then(res => {
      if (res.result === 200000) {
        walletList.value = res.data.list
        if (res.data.list.length > 0) {
          const find = res.data.list.find(item => formData.link === '' + item.link)
          formData.address = find ? find.default : ''
        } else {
          formData.address = ''
        }
      } else {
        walletList.value = []
        if (res.result === 1337) {
            Toast(res.msg)
        }
      }
    }).catch(() => {
      walletList.value = []
    })
  }

  const filterAddress = computed(() => {
    if (isRecharge.value) {
      const newData = walletList.value.find(item => formData.link === '' + item.link)
      if (newData) {
        const data = newData.address.map((item, index) => {
          return {
            id: index + 1,
            address: item
          }
        })
        return data
      }
      return []
    }
    return []
  })

  onMounted(() => {
    getBitRateInfo()
    if (isRecharge.value) {
      getBitConfigInfo()
    }
  })

  // 点击导航栏左侧返回键/取消按钮
  const onClickLeft = () => {
    history.back()
  }

  const bitType = ref('')

  //    比特币链路，类型，充值地址选择数据
  const columns = computed(() => {
    //  钱包地址使用的id和address，静态数据也使用这种渲染
    if (bitType.value === 'link') {
      return [
        { id: '1', address: 'etc-20' },
        { id: '2', address: 'erc-20' },
        { id: '3', address: 'trc-20' }
      ]
    }
    if (bitType.value === 'type') {
        return [{ id: '1', address: 'USDT' }]
    }
    return filterAddress.value
  })

  //    链路选择器开关
  const showPicker = ref(false)

  // 复制钱包地址
  const { toClipboard } = useClipboard()

  //    充值选择的钱包地址复制
  const copyAddress = async (content) => {
    if (content === '') {
      return false
    }
    if (isRecharge.value) {
      try {
        await toClipboard(content)
        Toast.success('USDT地址已复制')
      } catch (e) {
        console.error(e)
      }
    }
  }

  //    比特币链路，类型，充值地址选择
  const onConfirm = (currentValue) => {
    if (bitType.value === 'link') {
      formData.link = currentValue.id
      formData.linkText = currentValue.address
      // if (isRecharge.value) {
      //   const newData = walletList.value.find(item => currentValue.id === '' + item.link)
      //   formData.address = newData ? newData.default : ''
      // }
    }
    if (bitType.value === 'type') {
      formData.type = currentValue.id
      formData.typeText = currentValue.address
    }
    if (bitType.value === 'wallet') {
      formData.address = currentValue.address
    }
    showPicker.value = false
  }

  //    防止用户频繁点击
  const locked = ref(false)

  //    充值提交执行
  const executeRecharge = () => {
    if (locked.value) {
      return false
    }
    locked.value = true
    const params = {
      link: formData.link,
      type: formData.type,
      address: formData.address,
      amount: formData.amount,
      is_rate: status.value,
      pay_pic: formData.pay_pic,
      wallet_type: store.state.publicConfig.title
    }
    postBitRecharge({ ...params }).then(res => {
        setTimeout(() => locked.value = false, 10000)
        if (res.result === 200000) {
            Toast.success(res.msg)
            // router.push('/order')
            router.push({
                path: '/order',
                query: {
                  bitType: 1
                }
            })
        } else {
            Toast.fail(res.msg)
        }
    }).catch(() => {
      setTimeout(() => locked.value = false, 10000)
      Toast.fail('操作失败')
    })
  }

  //    提现提交执行
  const executeWithdraw = () => {
    if (+currentSysAmount.value > +store.state.memberAmountObj.currency_money) {
      Toast.fail('当前账户余额不足')
      return false
    }
    
    if (locked.value) {
      return false
    }
    locked.value = true
    const params = {
      link: formData.link,
      type: formData.type,
      address: formData.address,
      amount: formData.amount,
      is_rate: status.value,
      wallet_type: store.state.publicConfig.title,
      pay_password: formData.pay_password
    }
    postWithdrawOrderAddV1({ ...params }).then(res => {
      setTimeout(() => locked.value = false, 10000)
      if (res.result === 200000) {
        Toast.success(res.msg)
        router.push({
          path: '/order',
          query: {
            bitType: 2
          }
        })
      } else {
        Toast.fail(res.msg)
      }
    }).catch(() => {
      setTimeout(() => locked.value = false, 10000)
      Toast.fail('操作失败')
    })
  }

  //    表单保存前的验证
  const validata = () => {
    //  输入的usdt数量
    if (formData.amount === '') {
      Toast.fail('请输入USDT数量')
      return false
    }
    //  充值/提现数量必须大于最低配置的数量
    if ((+minAmount.value) > (+formData.amount)) {
      const msg = isRecharge.value ? '充值的USDT数量小于最低配置限制' : '提现的USDT数量小于最低配置限度'
      Toast.fail(msg)
      return false
    }
    //  usdt地址
    if (formData.address === '') {
      Toast.fail(isRecharge.value ? '请选择USDT地址' : '请输入提现的USDT-TRC20地址')
      return false
    }
   
    //  地址验证
    if (!/^[a-zA-Z0-9]+$/.test(formData.address)) {
      Toast.fail('USDT地址只能包含英文和数字')
      return false
    }
    return true
  }

  //    表单上传前验证
  const clickUpload = (event) => {
    if (!validata()) {
      event.preventDefault()
    }
  }

  //    是否只读
  const isReadOnly = ref(0)
  //    上传凭证遮罩
  const showOverlay = ref(false)
  // 上传支付凭证前
  const beforeUpload = () => {
    showOverlay.value = true
    formData.pay_pic = ''
    formData.imgUrl = ''
    return true
  }
  // 上传支付凭证后
  const afterUpload = (file) => {
    // 此时可以自行将文件上传至服务器
    file.status = 'uploading'
    const newData = new FormData()
    newData.append('file', file.file)
    newData.append('file_name', 'centos')
    uploadImage(newData).then(res => {
      if(res.result == 200000) {
        isReadOnly.value += 1
        formData.pay_pic = res.data.path
        formData.imgUrl = res.data.url
        file.status = 'success'
        showOverlay.value = false
      } else {
        isReadOnly.value -= 1
        file.status = 'failed';
        file.message = '上传失败';
        showOverlay.value = false
        formData.pay_pic = ''
        formData.imgUrl = ''
        Toast.fail(res.msg)
      }
    })
  }

  //    表单保存
  const onSubmit = () => {
    if (validata()) {
      if (isRecharge.value) {
        if (formData.pay_pic) {
          executeRecharge()
        }
      } else {
        if (!formData.pay_password) {
          Toast.fail('支付密码不能为空')
          return false
        }
        executeWithdraw()
      }
    }
  }

  //    查看支付凭证
  const showFullImage = () => {
    if (formData.imgUrl === '') {
      return false
    }
    ImagePreview({
      images: [formData.imgUrl]
    })
  }

  //  最低充值/提现金额
  // const systemConfig = computed(() => store.state.sysConfig)
</script>

<style scoped lang="less">
.recharge-wrap {
    position: relative;
    height: 100vh;
    padding-bottom: 50px;

    .deposit-header-box {
      position: fixed;
      top: 0;
      width: 100%;
      height: 58px;
      background: var(--mainBgColor);
      z-index: 1;
      .direction {
        border-top: 12px solid var(--mainBgColor);
      }
    }

    .recharge-box {
        padding: 78px 15px 59px 15px;
        //border-top: 12px solid var(--mainBgColor);
        overflow-y: auto;
        //height: calc(100vh - 105px);
        .form-item {
            margin-bottom: 20px;
            position: relative;
            .form-label {
                display: inline-block;
                margin-bottom: 4px;
                color: #535C66;
                font-family: PingFang SC;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                &.form-label2 {
                    text-align: right;
                    position: absolute;
                    right: 0;
                }
            }
            .form-input {
                height: 40px;
                flex-shrink: 0;
                border-radius: 4px;
                background: var(--van-cell-background-color);
                padding: 5px;
                display: flex;
                align-items: center;
                position: relative;
                .form-bit-input {
                    span {
                        margin-left: 10px;
                    }
                }
                .form-arrow {
                    position: absolute;
                    right: 5px;
                }
                &.form-input2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: var(--van-field-input-text-color);
                    font-family: PingFang SC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: var(--boxBgColor1);
                }
                &.form-input2-black {
                    background: transparent;
                    border: 1px solid var(--boxBgColor1);
                }
            }
            img {
                width: 100%;
            }
            .view-img {
                color: var(--activeItemTextColor);
            }
            .warning-message {
                color: red;
                font-size: 12px;
            }
        }
    }
    .submit-btn {
        //width: 351px;
        //border-radius: 8px;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        //bottom: 15px;
        background: var(--mainBgColor);
        width: 100%;
        left: 0;
        height: 60px;
        padding: 0 15px;
    }
    ::v-deep {
        .form-item {
            .left-bit-icon {
                .van-icon__image {
                    width: 24px;
                    height: 24px;
                }
            }
            .copy-address-icon {
                width: 20px;
                height: 20px;
            }
            .pay-certificate {
                position: relative;
                width: 100px;
                img {
                    opacity: 0.3;
                }
                .clear-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        .upload-pay {
            width: 100%;
            .van-uploader__wrapper {
                display: block;
            }
        }
        .submit-btn {
            .van-button {
              border-radius: 8px;
            }
        }
    }
}
</style>
